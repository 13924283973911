var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "menu",
      fn: function fn() {
        return [_vm.deleteUpdateActivity ? _c('div', [_vm.$vuetify.breakpoint.smAndDown ? _c('context-menu', [_c('v-list', [_vm.activityLogEntry ? _c('activity-time-line-item-delete', {
          attrs: {
            "text": true,
            "partnerId": _vm.activityLogEntry.partnerId,
            "activityId": _vm.activityLogEntry.id
          }
        }) : _vm._e()], 1)], 1) : _c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_c('v-btn', {
          staticClass: "mt-n1",
          attrs: {
            "x-small": "",
            "outlined": "",
            "color": "info",
            "disabled": !_vm.message
          },
          on: {
            "click": function click($event) {
              _vm.isMessageSideCardOpen = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemTicket.detail")) + " ")]), _vm.activityLogEntry ? _c('activity-time-line-item-delete', {
          attrs: {
            "partnerId": _vm.activityLogEntry.partnerId,
            "activityId": _vm.activityLogEntry.id
          }
        }) : _vm._e()], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.message ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "dense": "",
      "value": _vm.message.content.subject,
      "readonly": "",
      "outlined": "",
      "label": _vm.$t('components.template.dialog.preview.subject')
    }
  }), _c('template-editor', {
    staticClass: "mt-n8 pb-2",
    attrs: {
      "value": _vm.message.content.body,
      "readOnly": true,
      "outlined": true,
      "label": _vm.$t('components.template.dialog.preview.body')
    }
  })], 1)], 1) : _vm._e(), _vm.isMessageSideCardOpen ? _c('partner-message-side-card', {
    on: {
      "close": function close($event) {
        _vm.isMessageSideCardOpen = false;
      }
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
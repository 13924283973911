import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { ActivityLog } from "@/models/activity-log.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IPartnerMessage, PartnerMessage } from "@/models/partner-message.entity";
import { TimestampDocument } from "@/models/timestamp.entity";
import {
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module } from "vuex-module-decorators";
import { ActionEnum } from "../enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "../enum/authResourceEnum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ActivityLogDataAccessLayer } from "./access-layers/activity-log-service.access-layer";
import { PartnerMessageDataAccessLayer } from "./access-layers/partner-message.access-layer";
import { PaginationFilterListElement, PaginationFilterOperationEnum } from "./base-pagination.store";
import { IPartnerMessageStore } from "./inbox-message.store";
import { UserModule } from "./me-user.store";
import { PartnerMessagePageDataProvider } from "./page-data-provider/partner-message.page-data-provider";
import { PartnerModule } from "./partner";

@Module({
  dynamic: true,
  namespaced: true,
  name: "allmessages",
  store
})
export class AllMessagesStore
  extends PaginatedBaseStore<IPartnerMessage, MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen>
  implements IPartnerMessageStore {
  _data = PartnerMessageDataAccessLayer;
  _pageProvider = PartnerMessagePageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  protected _isLoadAll = true;
  filterOptions: PaginationFilterListElement[] = PartnerMessage.filterables;

  @Action
  async generateFrontendTimeLineElementsForMessagesAssociatedWithReference({
    refId,
    partnerId
  }: {
    refId: string;
    partnerId: string;
  }): Promise<void> {
    await this._generateTimeLineMessagesForFilter({
      partnerId,
      refId: refId,
      refType: BackendResourceEnum.REPORT,
      filters: [new PageFilterElement({ value: refId, key: "refs.refId", operation: PageFilterOperationEnum.EQUAL })]
    });
  }

  @Action
  async generateTimeLineMessagesForUser(data: {
    id: string;
    userName: string;
    contact?: { email: string };
    partnerId: string;
    resource: BackendResourceEnum.USER | BackendResourceEnum.CUSTOMER_DATA;
  }) {
    if (!data?.userName) {
      throw new Error("no user");
    }
    await AllMessagesModule.generateTimeLineMessagesForMail({
      refId: data.id,
      refType: data.resource,
      mail: data.userName,
      partnerId: data.partnerId
    });

    if (data.contact?.email && data.contact?.email !== data.userName) {
      await AllMessagesModule.generateTimeLineMessagesForMail({
        refId: data.id,
        refType: data.resource,
        mail: data.contact.email,
        partnerId: data.partnerId
      });
    }
  }

  @Action
  async generateTimeLineMessagesForMail(data: {
    refId: string;
    refType: BackendResourceEnum;
    partnerId: string;
    mail: string;
  }): Promise<void> {
    await this._generateTimeLineMessagesForFilter({
      ...data,
      filters: [new PageFilterElement({ value: data.mail, key: "from", operation: PageFilterOperationEnum.EQUAL })]
    });

    await this._generateTimeLineMessagesForFilter({
      ...data,
      filters: [new PageFilterElement({ value: data.mail, key: "to", operation: PageFilterOperationEnum.EQUAL })]
    });
  }

  @Action
  private async _generateTimeLineMessagesForFilter({
    refId,
    refType,
    partnerId,
    filters
  }: {
    refId: string;
    refType: BackendResourceEnum;
    partnerId: string;
    filters: IPageFilterElement[];
  }): Promise<void> {
    this.setFilters(filters);
    for (const message of this.filtered) {
      this.createLocalActivityLogForMessage({
        refType,
        refId,
        partnerId,
        message
      });
    }
    await this.fetchAll({ partnerId });
    for (const message of this.filtered) {
      this.createLocalActivityLogForMessage({
        refType,
        refId,
        partnerId,
        message
      });
    }
  }

  @Action
  private createLocalActivityLogForMessage({
    refType,
    refId,
    partnerId,
    message
  }: {
    refType: BackendResourceEnum;
    refId: string;
    partnerId: string;
    message: IPartnerMessage;
  }) {
    const data: MrfiktivCreateActivityLogDtoGen = {
      source: { refType: refType, refId: refId },
      target: [{ refType: BackendResourceEnum.MESSAGE, refId: message.id }],
      actionType: ActionEnum.CREATE,
      activity: ActivityTypeEnum.MESSAGE
    };
    const activityLog = new ActivityLog({
      ...data,
      id: refType + message.id,
      partnerId: partnerId,
      timestamp: new TimestampDocument(message.timestamp),
      userId: UserModule.userId,
      isDeletable: false
    });

    ActivityLogDataAccessLayer.set(activityLog);
  }

  @Action
  async reset() {
    super.reset();

    const partnerId = PartnerModule.partner.id;
    if (
      !PartnerModule.partner ||
      !PartnerModule.partner.id ||
      !UserModule.abilities.can(ActionEnum.READ, ResourceEnum.MESSAGE, partnerId)
    ) {
      return;
    }

    // no need to look up messages that belong into an inbox. these are already fetched @ inbox-message.store.ts .initializeModule()
    const filters = [];
    for (const inbox of PartnerModule.partner?.settings?.inboxes ?? []) {
      filters.push(
        new PageFilterElement({
          key: "to",
          operation: PaginationFilterOperationEnum.NOT_EQUAL,
          value: inbox.identifier
        })
      );
    }

    this.setFilters(filters);

    await this.fetchFirstPage({ partnerId });
  }
}

export const AllMessagesModule = getModule(AllMessagesStore);

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-14"
  }, [_c('m-header', {
    attrs: {
      "title": _vm.title,
      "subtitle": _vm.value.timestamp.createdReadable,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      },
      "alertClicked": function alertClicked($event) {
        return $event.exec();
      }
    }
  }), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "details",
      fn: function fn() {
        return [_c('refs-select', {
          staticClass: "mt-n2",
          attrs: {
            "partnerId": _vm.partnerId,
            "loading": _vm.isLoadingRefsUpdate,
            "small": false,
            "outlined": true,
            "draggable": true
          },
          on: {
            "change": _vm.updateRefs
          },
          model: {
            value: _vm.value.refs,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "refs", $$v);
            },
            expression: "value.refs"
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }, [_vm.isDa && _vm.daDossier ? _c('m-detail-table', {
    attrs: {
      "title": "Dossier",
      "item": _vm.daDossier,
      "config": _vm.daDetailTable
    }
  }) : _vm._e(), _c('operation-table', {
    staticClass: "ma-n2",
    attrs: {
      "baseQuery": {
        partnerId: _vm.partnerId
      }
    }
  }), _c('v-divider', {
    staticClass: "mt-2"
  }), _c('activity-card', {
    attrs: {
      "partner": _vm.partner,
      "source": _vm.source,
      "config": {
        comment: true,
        mail: false
      }
    }
  })], 1), _vm.isKsr ? _c('partner-report-detail-send-to-ksr-dialog', {
    ref: "partnerReportDetailSendToKsrDialog",
    attrs: {
      "thirdPartySystem": _vm.value
    }
  }) : _vm._e(), _vm.isDa ? _c('partner-report-detail-send-to-da-dialog', {
    ref: "partnerReportDetailSendToDaDialog",
    attrs: {
      "thirdPartySystem": _vm.value
    }
  }) : _vm._e(), _vm.isExportSignDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isExportSignDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('thirdPartySystem.exportSign'),
      "loading": _vm.isExportSignLoading,
      "hideLeft": false,
      "hideRight": false,
      "rightLoading": _vm.isExportSignLoading,
      "rightDisabled": !_vm.selected.length
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isExportSignDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isExportSignDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isExportSignDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isExportSignDialogActive = false;
      },
      "rightClick": _vm.exportSigns
    }
  }, _vm._l(_vm.signIds, function (item) {
    return _c('v-card', {
      key: 'refs-sign-request' + item.refId,
      staticClass: "my-1",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-action', [_c('v-checkbox', {
      attrs: {
        "value": item.refId
      },
      model: {
        value: _vm.selected,
        callback: function callback($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    })], 1), _c('v-list-item-content', [_c('refs-selected', {
      attrs: {
        "removable": false,
        "outlined": false,
        "confirmable": false,
        "draggable": false,
        "value": [item]
      }
    })], 1)], 1)], 1);
  }), 1) : _vm._e(), _vm.isDeleteDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error',
      "rightLoading": _vm.isLoadingDelete
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.value.system + " - " + _vm.value.externalId))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.value.timestamp.createdReadable) + " ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('v-card', {
    class: {
      'ma-0': _vm.$vuetify.breakpoint.smAndDown,
      'ma-2': _vm.$vuetify.breakpoint.mdAndUp
    },
    attrs: {
      "loading": _vm.loading,
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.cards.LatestReportEntriesCard.title")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-xs text--disabled text-truncate cursor-pointer",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.toReports
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.cards.LatestReportEntriesCard.button")) + " ")])], 1), _c('v-divider'), _c('v-card-text', [_vm.latestReports.length > 0 ? _c('latest-entries-card-list', {
    attrs: {
      "inputs": _vm.latestReports
    },
    on: {
      "click": function click(d) {
        return _vm.route(d.id);
      }
    }
  }) : _c('latest-entries-card-empty')], 1)], 1) : _c('v-card', [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, divider, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
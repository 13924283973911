import { render, staticRenderFns } from "./FleetVehicleDetailDocumentCard.vue?vue&type=template&id=19ffeec4&"
import script from "./FleetVehicleDetailDocumentCard.vue?vue&type=script&lang=ts&"
export * from "./FleetVehicleDetailDocumentCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCardActions,VFileInput,VSelect})
